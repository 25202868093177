import React from 'react'
import Header from './presentational/Header';
import '../styles/home.scss';
import { programs } from '../dataHelp';
import Footer from './presentational/Footer';
import { NavLink } from 'react-router-dom';

function Home() {
  const BG_IMAGE = `url(/imgs/section-bg.png)`;
  const bannerStyles = {
    backgroundImage: BG_IMAGE,
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: '510px'
  }
  const PROGRAM_SECTION_BG = `url(/imgs/program-sect-bg.png)`;
  const programBGStyles = {
    backgroundImage: PROGRAM_SECTION_BG,
    backgroundPosition: "right center",
    backgroundSize: "contain",
    backgroundRepeat: 'no-repeat',
    backgroundSize: '480px'
  }

  const getBannerCont = () => {
    return (
      <>
        <h1 className="font-bold mt-5 pt-4 font-75">WHERE <span className="text-primary">MATH</span> MAKE SENSE</h1>
        <p className="mb-2 mt-4 font-18 px-5">At Equation Hub, we believe every student can excel in math with the right support Our mission is to make math engaging and accessible for learners from Kindergarten to Grade 12.</p>
        <p className="font-18">Our curriculum aligns with the Ontario Math Curriculum, helping students meet and exceed provincial standards. We offer personalized learning plans tailored to each student's pace and style, from basic skills to advanced concepts.</p>
        <div className="for-mobile-only my-3"><NavLink  as="button" to="/registration" className="btn contantBtn px-3 py-2">Register Now</NavLink></div>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <img className="mt-2" width="100%" src='/imgs/home-banner-img.png' />
          </div>
        </div>
      </>
    )
  }
  const getBannerSection = () => {
    return (
      <>
        <div className="home-banner-cont position-relative" style={bannerStyles}>
          <div className="arrow left mt-4"><img src='/imgs/left-arrow.png' /></div>
          <div className="row justify-content-center pb-5">
            <div className="col-md-8 px-0 text-center">
              {getBannerCont()}
            </div>
          </div>
        </div>
      </>
    )
  }
  const getProgramCard = (program, index) => {
    return (
      <>
        {index === 2 && <div key={program.key} className="col-md-4"></div>}
        <div key={program.key} className="col-md-4 px-4">
          <div className={`program-card ${program.key}`}>
              <img src={program.imgSrc} />
              <p className="mt-3 font-bold">{program.title}</p>
              <p className="font-12 desp text-dark">{program.desp}</p>
              <div className="points font-bold">
                {program.points.map((point)=>{
                  return <p key={point} className="font-12 mb-2">{point}</p>
                })}
              </div>
              <p className="font-12 text-dark mt-3">{program.goal}</p>
          </div>
        </div>
      </>
    )
  } 
  const getProgramSection = () => {
    return(
      <div className="program-sect-cont position-relative mt-5 pb-5 pt-2" style={programBGStyles}>
        <div className="row justify-content-center">
          <div className="col-md-8 px-0 text-center">
            <h2 className="font-bold font-50">Programs we offer</h2>
            <p className="mb-2 mt-3 font-18 px-4">Equation Hub provides a range of programs to meet students' needs at every stage of their math journey. Our tailored programs build confidence, enhance skills, and foster a love for math. Explore our offerings</p>
          </div>
          <div className="col-md-11 px-4">
            <div className="row">
              {programs().map((program, index) => getProgramCard(program, index) )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="home-main-cont">
      <Header />
      {getBannerSection()}
      {getProgramSection()}
      <Footer/>
    </div>
  )
}

export default Home