import React from 'react'
import { getOurApproachData } from '../dataHelp'
import Footer from './presentational/Footer'
import Header from './presentational/Header'

function Aboutus() {
  const getBannerSection = () => {
    return (
      <div
        className="aboutusBgCont"
        style={{ backgroundImage: "url(imgs/about.jpg)" }}
      >
        <div className="aboutusTxtCont">
          <div className="txt">
            <h1 className="font-bold font-60 mb-4">About Us</h1>
            <p className="mt-2 font-25">
              At Equation Hub, we are dedicated to empowering students to excel in mathematics, from
              Kindergarten to Grade 12. Our mission is simple: to make math accessible, enjoyable, and
              understandable for all students. Through personalized instruction, innovative teaching methods,
              and a supportive learning environment, we help every student build confidence and achieve
              their academic goals
            </p>
          </div>
        </div>
      </div>
    )
  }
  const ourStorySect = () => {
    return(
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-md-4">
          <img src="/imgs/about-us.jpg" style={{borderRadius: "1.25rem", objectFit: 'cover'}} height="310px" width="100%" />
        </div>
        <div className="col-md-6 ps-5">
          <h2 className="font-bold font-50">Our Story</h2>
          <p className="mb-2 mt-3 font-18">Founded by Mr. Malik, an Ontario Certified Teacher with a passion for education, Equation Hub
            was created to address the unique learning needs of every child. Mr. Malik has been tutoring
            kids in his community since he was in grade 10, which was back in 2009. With a background in
            Early Childhood Development, a Bachelor’s in Child Development, and a Bachelor’s in
            Education, he has taught students ranging from Kindergarten to Grade 11. Drawing from years
            of experience as a Kindergarten teacher, Mr. Malik saw the need for a math tutoring center that
            goes beyond traditional methods. He focused on creating a center that emphasizes
            individualized support, interactive learning, and a genuine love for the subject.</p>
        </div>
      </div>
    )
  }
  const ourApproachSect = () => {
    return (
      <div className="row justify-content-center mt-5 pt-3">
        <div className="col-md-9 px-0 text-center">
          <h2 className="font-bold font-50">Our Approach</h2>
          <p className="mb-2 font-18 px-4">At Equation Hub, we believe in a holistic and student-centered approach to learning</p>
          <div className="row mt-5 approach-sect-cont">
            {getOurApproachData().map((item, index) => {
              return (
                <div key={item.icon} className="col-md-4 col-8">
                  <div className="border-0 card about-us-card px-4 py-2 text-start">
                    <i className={`${item.icon} text-primary font-50 mt-3 mb-3`} />  
                    <h5 className="font-25 mb-2">{item.title}</h5>
                    <p className="text-muted">{item.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
  const contactUsSect = () => {
    return(
      <div className="row justify-content-center mt-5 pt-3">
        <div className="col-md-11 px-0">
          <h2 className="font-bold font-50 text-center">Contact Us</h2>
          <div className="row justify-content-around mt-5">
            <div className="col-md-5">
              <p className="font-18 mb-5">Ready to learn more or schedule a free consultation? Contact us today and discover how Equation Hub can help your child succeed in math!</p>
              <div className="d-flex aic mt-4">
                <div className="icon me-2 text-center">
                  <i className="fas fa-envelope text-primary" />
                </div>
                <a href="mailto:equationhub.info@gmail.com" className="text-decoration-none text-dark">equationhub.info@gmail.com</a>
              </div>
              <div className="d-flex aic mt-4">
                <div className="icon me-2 text-center">
                  <i className="fas fa-phone text-primary" />
                </div>
                <a href="tel:+19054096284" className="text-decoration-none text-dark">(905) 409-6284</a>
              </div>
              <div className="d-flex aic mt-4">
                <div className="icon me-2 text-center">
                  <i className="fas fa-map-marker-alt text-primary" />
                </div>
                <a className="text-decoration-none text-dark" href="https://www.google.com/maps/place/Equation+Hub/@44.009253,-79.4739839,17z/data=!4m6!3m5!1s0x882ad18f9dd87431:0x9d62cdfa8c00f5a0!8m2!3d44.009253!4d-79.471409!16s%2Fg%2F11wc0dn116?entry=ttu&g_ep=EgoyMDI0MTAwMS4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                  2 Orchard Heights Blvd Unit 31, Aurora, ON L4G 6T5
                </a>
              </div>
            </div>
            <div className="col-md-6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2869.587482449728!2d-79.4739839!3d44.009253!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ad18f9dd87431%3A0x9d62cdfa8c00f5a0!2sEquation%20Hub!5e0!3m2!1sen!2s!4v1728932962918!5m2!1sen!2s" width="100%" height="450" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="about-page">
      <Header />
        {getBannerSection()}
        {ourStorySect()}
        {ourApproachSect()}
        {contactUsSect()}
      <Footer />
    </div>
  )
}

export default Aboutus